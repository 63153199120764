<template>
  <div>
    <Navbar page="Questionários de diagnóstico" />
    <div class="mx-4 my-4 md:mx-8 md:my-8">
      <div class="grid grid-cols-12 gap-3 mb-3">
        <div class="col-span-12 xl:col-span-3 mb-1 mt-1">
            <select v-model="tipoDiagnostico" name="nivelUrgencia" id="nivelUrgencia" @change="start" class="mt-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
            <option default value="interno">Diagnóstico interno</option>
            <option value="externo">Diagnóstico externo</option>
          </select>
        </div>
      </div>
      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                    <tr>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                        Data
                      </th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                        Diagnóstico
                      </th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                        Tema
                      </th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                        Perguntas
                      </th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                        Status
                      </th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                        Opções
                      </th>
                    </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                    <tr v-for="item in list" :key="item._id">
                      <td class="px-6 py-4">
                        <div class="flex items-center">
                          <div class="text-sm text-gray-500">
                            {{ formatDate(item.criadoEm) }}
                          </div>
                        </div>
                      </td>
                      <td class="px-6 py-4">
                        <div class="flex items-center">
                          <div class="text-sm text-gray-500">
                            {{ tipoDiagnostico }}
                          </div>
                        </div>
                      </td>
                      <td class="px-6 py-4">
                        <div class="flex items-center">
                          <div class="text-sm text-gray-500">
                            {{ item.diag_base.tema }}
                          </div>
                        </div>
                      </td>
                      <td class="px-6 py-4 text-center">
                        <div class="flex items-center ">
                          <div class="text-sm text-gray-500 ">
                            {{ item.perguntas.length }}
                          </div>
                        </div>
                      </td>
                      <td class="px-6 py-4">
                        <div class="flex items-center">
                          <div class="text-sm text-gray-500">
                            {{ item.status }}
                          </div>
                        </div>
                      </td>
                      <td class="flex flex-wrap px-6 py-4 text-sm font-medium gap-0.5 items-center">
                          <router-link 
                            v-if=" item.status === 'Finalizado'"
                            type="button" 
                            :to="`/diagnosticos/answer/${item._id}`" 
                            class="text-white hover:bg-gray-500 bg-gray-400 rounded py-1 px-4 mx-1 ">
                              Visualizar
                          </router-link>
                          <router-link 
                            v-else
                            type="button" 
                            :to="`/diagnosticos/answer/${item._id}`" 
                            class="text-white hover:bg-yellow-500 bg-yellow-400 rounded py-1 px-4 mx-1 ">
                             Responder
                          </router-link>
                      </td>
                    </tr>
                </tbody>
              </table>
            </div>
            <pagination v-if="total > 0" v-model="query.page" :per-page="perPage" :records="total" @paginate="setPage" class="pagination" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  data() {
    return {
      route: '/diagnosticos/visualizar',
      arquivados: false,
      list: [],
      tipoDiagnostico: 'interno',
      page: 1,
      perPage: 50,
      total: 0,
      query: { page: 1, limit: 50, skip: 0  },
    }
  },
  methods: {

    formatDate(data) {
      return `${moment(data).format("DD/MM/YYYY HH:mm:ss")}`;
    },

    async start() {
      if(this.tipoDiagnostico === 'interno'){
        await this.$http
          .get(`/v1/diagnosticoAplicado/questionarPorCliente/${this.$store.state.user._id}?skip=${this.query.skip}&page=${this.query.page}&limit=${this.query.limit}`)
          .then((resp)=>{
          this.list = resp.data.itens;
          this.total = resp.data.total;
        })
      }else{
        this.list = [];
        this.total = 0;
      }
    },
    async setPage(page) {
      const skip = (page - 1) * this.perPage;
      const limit = this.perPage;

      this.query.page = page;
      this.query.skip = skip;
      this.query.limit = limit;
      this.query.arquivados = this.arquivados;
      this.start(this.$route.params.id);
    }
  },
  async beforeMount() {
    this.start(this.$route.params.id);
  },
}
</script>